<template>
  <!-- 预约取消 -->
  <div class="appointment-cancellation">
    <!-- 预约信息 -->
    <div class="appointment-card">
      <div>
        <span>就诊人:</span>
        <span>{{ cardList[0].name }}</span>
      </div>
      <div>
        <span>科室:</span>
        <span>{{ $route.query.departMent }}</span>
      </div>
      <div>
        <span>医生:</span>
        <span>{{ $route.query.doctor }}</span>
      </div>
      <div>
        <span>就诊日期:</span>
        <span>{{ $route.query.diagnosisTime }}</span>
      </div>
      <div>
        <span>就诊时段:</span>
        <span>{{ shiftTime[$route.query.shift] }}</span>
      </div>
    </div>
    <div>
      <van-button
        class="button"
        type="primary"
        @click="cancel"
        v-show="$route.query.flag == 1 ? false : true"
        block
      >
        预约取消
      </van-button>
    </div>
  </div>
</template>

<script>
import { jd2hisAppointmentCancel, updateLogFunTrade } from '@/network/service'
import { mapState } from 'vuex'
export default {
  name: 'appointmentCancellation',
  data() {
    return {
      //号源对应时间
      shiftTime: {
        '1': '全天',
        '2': '06:00-12:00',
        '3': '14:30-17:00',
        '20': '08:00-08:30',
        '21': '08:30-09:00',
        '22': '09:00-09:30',
        '23': '09:30-10:00',
        '24': '10:00-10:30',
        '25': '10:30-11:00',
        '26': '11:00-11:30',
        '27': '11:30-12:00',
        '28': '14:30-15:00',
        '29': '15:00-15:30',
        '30': '15:30-16:00',
        '31': '16:00-16:30',
        '32': '16:30-17:00',
        '33': '14:00-14:30',
        '36': '17:00-17:30',
      },
    }
  },
  computed: {
    ...mapState(['seq', 'cardList']),
  },
  created() {
    console.log(this.$route.query)
  },
  methods: {
    // 预约取消
    cancel() {
      this.$dialog
        .confirm({
          title: '',
          message: '是否取消预约',
        })
        .then(async () => {
          //取消预约
          let res = await jd2hisAppointmentCancel({
            seq: this.seq,
            order_no: this.$route.query.registerSeq,
            name: this.cardList[0].name,
            id_no: this.cardList[0].idNo,
            phone: this.cardList[0].phone,
            sex: this.$store.state.head.sex,
            bid: this.$route.query.bid,
          })
          if (res.code == '0') {
            //更新流水号
            await updateLogFunTrade({
              seq: this.seq,
              finishFlag: '完成预约取消',
              card_type: this.cardList[0].cardType,
              card_no: this.cardList[0].cardNo,
              idno: this.cardList[0].idNo,
              name: this.cardList[0].name,
            })
            this.$router.go(-1)
            this.$toast('取消成功')
          } else {
            this.$toast(res.msg)
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.button {
  width: 80vw;
  margin: auto;
  margin-top: 2rem;
  background-color: @darkColor;
  border-radius: 2rem;
  border: none;
}
.appointment-cancellation {
  background-color: #f4f4f4;
  height: 100%;
  .appointment-card {
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    padding: 1rem 0;
    padding-left: 1rem;
    border-radius: 1rem;
    background-color: rgba(254, 254, 254);
    width: 90vw;
    line-height: 2rem;
    div {
      display: flex;
      span:first-child {
        flex: 0.3;
        color: #8d8fa1;
      }
      span {
        flex: 0.6;
      }
    }
  }
}
</style>
